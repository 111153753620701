<template>
  <el-drawer
    :title="dataForm.freezerName + '—' + dataForm.periodName + '—中转架管理'"
    :visible.sync="visible"
    direction="rtl"
    append-to-body
    destroy-on-close
    :close-on-press-escape="false"
    :wrapper-closable="false"
    class="categoryDrwer"
  >
    <div class="drawContainer">
      <div class="rightTill">中转架</div>
      <el-button
        @click="
          $dialog(
            'freezerShelf',
            null,
            dataForm.orgPeriodFreezerId,
            dataForm.freezerId,
            dataForm.periodId,
            shelf,
            false,
          )
        "
        size="mini"
        >添加中转架</el-button
      >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="
            item.index === 0 ? '1号架' : item.index === 1 ? '2号架' : '3号架'
          "
          :name="
            item.index === 0 ? '1号架' : item.index === 1 ? '2号架' : '3号架'
          "
          v-for="item in tableData"
          :key="item.index"
        >
        </el-tab-pane>
      </el-tabs>
      <el-table
        :data="dataList"
        border
        stripe
        show-summary
        :summary-method="getSummaries"
        ref="dataList"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="rowNum"
          header-align="center"
          align="center"
          label="行号"
        >
        </el-table-column>
        <el-table-column
          prop="colNum"
          header-align="center"
          align="center"
          label="列号"
        >
        </el-table-column>
        <el-table-column
          prop="spaceNum"
          header-align="center"
          align="center"
          label="占用仓位数"
        >
        </el-table-column>
        <el-table-column
          prop="position"
          header-align="center"
          align="center"
          label="摆放位置"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.position === 1" type="success" size="mini"
              >下</el-tag
            >
            <el-tag v-if="scope.row.position === 2" type="success" size="mini"
              >中</el-tag
            >
            <el-tag v-if="scope.row.position === 3" type="success" size="mini"
              >上</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          header-align="center"
          align="center"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          width="220"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              v-if="isAuth('tc:orgperiod:info')"
              type="text"
              size="small"
              @click="$dialog('freezerShelf', scope.row.id, true)"
              >查看</el-button
            >
            <el-button
              v-if="isAuth('tc:orgperiod:delete')"
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <shelf-add-or-update
      v-if="freezerShelfVisible"
      ref="freezerShelf"
      @success="getDataList()"
    />
  </el-drawer>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import shelfAddOrUpdate from './freezer-shelf-add-or-update';
import { getPeriodList } from '@/utils/options.js';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      freezerShelfVisible: false,
      activeName: '1号架',
      shelf: 2,
      dataForm: {
        id: 0,
        freezerId: '',
        orgPeriodFreezerId: '',
        freezerName: '',
        periodId: '',
        periodName: '',
        specId: 1,
      },
      periodList: [],
      tableData: [{ index: 0 }],
      dataList: [],
      dataRule: {},
    };
  },
  created() {
    this.getPeriodList();
  },
  components: {
    shelfAddOrUpdate,
  },
  methods: {
    // 货架切换
    handleClick(tab) {
      this.activeName = tab.name;
      if (tab.name === '1号架') {
        this.dataForm.specId = 1;
      } else if (tab.name === '2号架') {
        this.dataForm.specId = 2;
      } else if (tab.name === '3号架') {
        this.dataForm.specId = 3;
      }
      this.getDataList();
    },
    init(
      orgPeriodFreezerId,
      periodId,
      periodName,
      freezerId,
      freezerName,
      specId,
      disabled,
    ) {
      this.dataForm.orgPeriodFreezerId = orgPeriodFreezerId
        ? orgPeriodFreezerId
        : this.dataForm.orgPeriodFreezerId;
      this.disabled = disabled ? disabled : this.disabled;
      this.visible = true;
      this.dataForm.freezerName = freezerName
        ? freezerName
        : this.dataForm.freezerName;
      this.dataForm.freezerId = freezerId ? freezerId : this.dataForm.freezerId;
      this.dataForm.periodId = periodId ? periodId : this.dataForm.periodId;
      this.dataForm.periodName = periodName
        ? periodName
        : this.dataForm.periodName;
      this.shelf = specId ? specId : this.shelf;
      this.dataForm.specId = specId ? 1 : this.dataForm.specId;
      if (this.shelf === 2) {
        this.tableData = [{ index: 0 }, { index: 1 }];
      } else {
        this.tableData = [{ index: 0 }];
      }
      this.$nextTick(() => {
        if (this.dataForm.orgPeriodFreezerId) {
          this.$http({
            url: `/tc/periodFreezerShelf/freezerShelf`,
            method: 'get',
            params: {
              orgPeriodFreezerId: this.dataForm.orgPeriodFreezerId,
              specId: this.dataForm.specId,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataList = data.freezerShelf;
            }
          });
        }
      });
    },
    getDataList() {
      this.init();
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (column.property === 'spaceNum') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对选中的数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/periodFreezerShelf/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
