<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
    >
      <div class="tablePart">
        <el-form-item label="货架号" prop="shelf">
          <el-select v-model="dataForm.shelf" filterable :disabled="disabled">
            <el-option
              v-for="(item, index) in specList"
              :key="index"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="中转架规格" prop="shelfStyleId">
          <el-select
            v-model="dataForm.shelfStyleId"
            filterable
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in shelfStyleList"
              :key="index"
              :label="item.shelfStyle"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="摆放位置" prop="position">
          <el-select
            v-model="dataForm.position"
            filterable
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in positionList"
              :key="index"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { normal, listMixin } from '@/mixins';

export default {
  mixins: [normal, listMixin],
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        orgPeriodFreezerId: '',
        freezerId: '',
        periodId: '',
        shelfStyleId: '',
        position: '',
        shelf: 1,
      },
      shelfStyleList: [],
      specList: [{ id: 1, value: '1号架' }],
      positionList: [
        { id: 1, value: '下' },
        { id: 2, value: '中' },
        { id: 3, value: '上' },
      ],

      dataRule: {
        shelf: [{ required: true, message: '货架号不能为空', trigger: 'blur' }],
        shelfStyleId: [
          { required: true, message: '中转架规格不能为空', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '摆放位置不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getShelfStyle();
  },

  methods: {
    init(id, orgPeriodFreezerId, freezerId, periodId, specId, disabled) {
      this.disabled = disabled;
      if (specId === 2) {
        this.specList = [
          { id: 1, value: '1号架' },
          { id: 2, value: '2号架' },
        ];
      }
      this.dataForm.id = id;
      this.dataForm.orgPeriodFreezerId = orgPeriodFreezerId;
      this.dataForm.freezerId = freezerId;
      this.dataForm.periodId = periodId;
      this.dataForm.specId = specId;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/periodFreezerShelf/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.periodfreezershelf };
            }
          });
        }
      });
    },
    // 表单提交,只提交部分参数
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/periodFreezerShelf/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              ...this.dataForm,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: '操作失败',
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },

    getShelfStyle() {
      this.$http({
        url: '/cc/shelfstyle/shelfStyle',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.shelfStyleList = data.shelfStyleList;
        } else {
          this.shelfStyleList = [];
        }
      });
    },
  },
};
</script>
